import { Box, Heading, Image, Stack, Text, VStack, useToast } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import { $ok, $string, $tounsigned } from "foundation-ts/commons";
import { Resp } from "foundation-ts/tsrequest";
import { UUID } from "foundation-ts/types";
import { ActivationUserDto } from "g1-commons/lib/doxecureClientTypes";

import { $g1IconURL, $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { G1Button } from "../../components/G1Button";
import { ComplexPasswordField } from "../../components/PasswordField/ComplexPasswordField";
import { PasswordField } from "../../components/PasswordField/PasswordField";
import VersionDisplay from "../../components/VersionDisplay";
import { config } from "../../config/config";
import { activateUser, checkActivationToken, recoverPassword } from "../../services/authentication.service";
import { defaultErrorToast, defaultInfoToast } from "../../utils/toast";

interface AccountValidationProperties {
    recovery: boolean ;
}

export const AccountValidation = (props: AccountValidationProperties) => {
    const { recovery } = props ;
    const {
        getValues,
        register,
        setValue
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
    });

    const toast = useToast() ;
    const [isSubmitting, setSubmitting] = useState(false) ;
    const [isSamePwd, setSamePwd] = useState(false) ;
    const [isValidatedConstraints, setValidatedConstraints] = useState(true) ;
    const [activationUserDto, setActivationUserDto] = useState<ActivationUserDto|null>(null) ;

    const { search } = useLocation() ;
    const searchParams = new URLSearchParams(search) ;
    const admin = $tounsigned(searchParams.get("admin")) ;
    const token = searchParams.get("token") ;
    if (!$ok(token)) {
        useEffect(() => {
            setSubmitting(true) ;
            toast(defaultErrorToast("Paramètre 'token' manquant. Vous allez être automatiquement redirigé vers la page d'authentification.")) ;
            const timer = setTimeout(() => {
                setSubmitting(false) ;
                navigate(config.app.loginURL!) ;
            }, 2000) ;

            return () => clearTimeout(timer) ;
        }, []) ;
        return <></>;
    }

    useEffect(() => {
        async function checkToken() {
            const [ret, status] = await checkActivationToken(token!) ;
            if (status === Resp.Created) {
                setActivationUserDto(ret as ActivationUserDto );
            } else {
                toast(defaultErrorToast(_errorString($string(ret)))) ;
                toast(defaultInfoToast("Vous allez être automatiquement redirigé vers la page d'authentification.")) ;
                const timer = setTimeout(() => {
                    setSubmitting(false) ;
                    navigate(config.app.loginURL!) ;
                }, 2000) ;

                return () => clearTimeout(timer) ;
            }
        }

        checkToken() ;
    }, [token]) ;

    const _errorString = (error: string): string => {
        switch ($string(error)) {
            case ErrorAccountValidation.EXPIRED_TOKEN.toString():
                return "Le token d'activation a expiré. Veuillez contacter le support afin qu'il vous en génère un nouveau" ;
            case ErrorAccountValidation.INVALID_TOKEN.toString():
                return "Erreur lors de la vérification de votre accès. Veuillez contacter le support" ;
            case ErrorAccountValidation.ALREADY_USED_TOKEN.toString():
                return recovery ? "Le changement de mot de passe a déjà été effectué" : "Votre compte a déjà été activé" ;
        }
    
        return "Erreur non gérée" ;
    }

    const handleSubmit = async event => {
        try {
            event.preventDefault() ;
            setSubmitting(true) ;
            let userId: string|UUID|null ;
            let message: string ;
            if (recovery) {
                userId = await recoverPassword({ token: token!, password: getValues("password"), verifyPassword: getValues("verifyPassword") }) ;
                message = "Votre mot de passe a été changé. Vous allez être automatiquement redirigé vers la page d'authentification" ;
            } else {
                userId = await activateUser({ token: token!, password: getValues("password"), verifyPassword: getValues("verifyPassword") }) ;
                message = "Votre compte a été activé. Vous allez être automatiquement redirigé vers la page d'authentification" ;
            }
            if ($ok(userId)) {
                toast(defaultInfoToast(message)) ;
            }
            setValue("password", "") ;
            setValue("verifyPassword", "") ;
            setTimeout(() => { 
                setSubmitting(false) ;
                navigate(admin === 1 ? config.app.adminLoginURL! : config.app.loginURL!) ;
            }, 2000);
        } catch (e) {
            setSubmitting(false) ;
            toast(defaultErrorToast("Erreur lors de l'activation de votre compte")) ;
        }
    }

    const handlePasswordChange = async (pwd: string, isValidatedConstraints: boolean) => {
        setValue("password", pwd) ;
        setValidatedConstraints(isValidatedConstraints) ;
        setSamePwd(getValues("verifyPassword") === getValues("password")) ;
    }

    const handleConfirmPasswordChange = async (event) => {
        const pwd = $string(event.currentTarget.value).trim() ;
        setValue("verifyPassword", pwd) ;
        setSamePwd(getValues("verifyPassword") === getValues("password")) ;
    }

    return (
        <Box py="5%">
            <Box color={$g1Color('connection.logo.write')} bg={$g1Color('connection.logo.bg')} paddingTop="8" paddingBottom="2" px="8" maxW="md" mx="auto" rounded={{ base: "0", md: "2xl" }}>
                <VStack spacing="4" align="stretch">
                    <Stack direction="row" alignItems="center" spacing="2">
                        <Image src={$g1IconURL('connection.logo.iconurl')} alt="Application logo" boxSize="72px" />
                        <Heading as="h1" textAlign="center" size="lg" fontWeight="bold">
                            {config.app.title}
                        </Heading>
                    </Stack>
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                        Veuillez définir votre mot de passe
                    </Text>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="6" bg={$g1Color('connection.form.bg')} color={$g1Color('connection.form.write')} py="8" px={{ base: "4", md: "10" }} shadow="base" rounded="lg" paddingBottom="1em">
                            { recovery ? '' :
                                    <Text textAlign="left">
                                        { $ok(activationUserDto) ? `${activationUserDto!.firstName} ${activationUserDto!.lastName}` : "unknown name" }
                                        <br/>
                                        { $ok(activationUserDto) ? `${activationUserDto!.email}` : "unknown email" }
                                    </Text>
                            }
                            <ComplexPasswordField
                                registerInput={{...register("password", { required: true })}}
                                passwordConstraints={config.passwordConstraints}
                                component="connection.form"
                                onChange={handlePasswordChange}
                            />
                            <PasswordField
                                required label="Confirmation"
                                registerInput={{...register("verifyPassword", { required: true })}}
                                component="connection.form"
                                onChange={handleConfirmPasswordChange}
                            />
                            <G1Button component="connection" variant="ok" title="Valider" type="submit" name="validate" size="lg" isLoading={ isSubmitting } disabled={ !$ok(activationUserDto) || isSubmitting || !isValidatedConstraints || !isSamePwd } />
                        </Stack>
                    </form>
                    <VersionDisplay />
                </VStack>
            </Box>
        </Box>
    ) ;
}

enum ErrorAccountValidation {
    ALREADY_USED_TOKEN = "ALREADY_USED_TOKEN",
    INVALID_TOKEN = "INVALID_TOKEN",
    EXPIRED_TOKEN = "EXPIRED_TOKEN",
}