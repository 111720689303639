import { Center, HStack, StyleProps, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

import { $length, $ok, $string } from "foundation-ts/commons";
import { uint8 } from "foundation-ts/types";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { G1Icon } from "../../utils/icons";
import { Component } from "../../utils/TypesAndConstants";

import { PasswordField } from "./PasswordField" ;

export interface PasswordConstraints {
    minSize: uint8 ;
    mustHaveLowercase: boolean ;
    mustHaveUppercase: boolean ;
    mustHaveDigit: boolean ;
    mustHaveSpecial: boolean ;
}

export interface ComplexPasswordProperties extends StyleProps {
    component: Component ;
    passwordConstraints: PasswordConstraints ;
    onChange: (pwd: string, isValidated: boolean) => void ;
    registerInput: any ;
}

export const ComplexPasswordField = (props: ComplexPasswordProperties) => {
    const { component, passwordConstraints, onChange, registerInput, ...rest } = props;

    const [password, setPassword] = useState<string>("") ;
    const [hasLowercaseLetter, setHasLowercaseLetter] = useState(false) ;
    const [hasUpercaseLetter, setHasUpercaseLetter] = useState(false) ;
    const [hasDigit, setHasDigit] = useState(false) ;
    const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false) ;
    const [hasMinLength, setHasMinLength] = useState(false) ;

    const minCharacters = `${passwordConstraints.minSize} caractères minimum` ;

    const checkFailedConstraints= () => {
        return !hasMinLength                                               ||
            (passwordConstraints.mustHaveLowercase && !hasLowercaseLetter) ||
            (passwordConstraints.mustHaveUppercase && !hasUpercaseLetter)  ||
            (passwordConstraints.mustHaveDigit && !hasDigit)               ||
            (passwordConstraints.mustHaveSpecial && !hasSpecialCharacter) ;
    }

    const handlePasswordChange = (event) => {
        const pwd = $string(event.currentTarget.value).trim() ;

        setHasLowercaseLetter(pwd.search(/[a-z]+/) !== -1) ;
        setHasUpercaseLetter(pwd.search(/[A-Z]+/) !== -1) ;
        setHasDigit(pwd.search(/[0-9]+/) !== -1) ;
        setHasSpecialCharacter(pwd.search(/[^A-Za-z 0-9]/g) !== -1) ;
        setHasMinLength(pwd.length >= passwordConstraints.minSize) ;
        setPassword(pwd) ;
        onChange(pwd, !checkFailedConstraints()) ;
    }

    return (
        <>
            <PasswordField
                required
                label="Mot de passe"
                component={component}
                registerInput={ registerInput }
                onChange={ handlePasswordChange }
                {...rest} />
            <VStack alignItems="flex-start" marginTop="0.5rem !important">
                <HStack>
                    <PasswordConstraint name={minCharacters} hasChecked={ $length(password) !== 0 ? hasMinLength : undefined } />
                    { passwordConstraints.mustHaveSpecial ? <PasswordConstraint name="1 caractère spécial" hasChecked={ $length(password) !== 0 ? hasSpecialCharacter : undefined } /> : "" }
                </HStack>
                <HStack>
                    { passwordConstraints.mustHaveUppercase ? (<PasswordConstraint name="1 majuscule" hasChecked={ $length(password) !== 0 ? hasUpercaseLetter : undefined } />) : "" }
                    { passwordConstraints.mustHaveLowercase ? <PasswordConstraint name="1 minuscule" hasChecked={ $length(password) !== 0 ? hasLowercaseLetter : undefined } /> : "" }
                    { passwordConstraints.mustHaveDigit ? <PasswordConstraint name="1 chiffre" hasChecked={ $length(password) !== 0 ? hasDigit : undefined } /> : "" }
                </HStack>
            </VStack>
        </>
    ) ;
}

interface PasswordConstraintProperties {
    name: String,
    hasChecked?: boolean
}

const PasswordConstraint = (props: PasswordConstraintProperties) => {
    if ($ok(props.hasChecked)) {
        return (
            <HStack>
                { props.hasChecked ? <Center color={$g1Color('global.icons.complexPwd.ok.write')}><G1Icon.GoodPwdConstraint size="0.8rem" display="inline-block" /></Center> : 
                    <Center color={$g1Color('global.icons.complexPwd.nok.write')}><G1Icon.WrongPwdConstraint size="0.8rem" display="inline-block" color={$g1Color('global.icons.complexPwd.nok.write')} /></Center>
                }
                <div style={{ display: "inline-block", fontSize:"0.8rem", marginLeft:"0.1rem" }}>{ props.name }</div>
            </HStack> 

        );
    } else {
        return <div style={{ display: "inline-block", fontSize:"0.8rem", marginRight:"0.2rem" }}>{ props.name }</div> ;
    }
} ;
