import React from "react";

import { PublicLayout } from "../components/layouts/PublicLayout";
import { AccountValidation } from "../containers/login/AccountValidation";

const AccountValidationPage = () => {
    return (
        <PublicLayout>
            <AccountValidation recovery={false} />
        </PublicLayout>
    ) ;
}

export default AccountValidationPage ;