import {
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  InputGroup,
  InputRightElement,
  StyleProps,
  useDisclosure,
  useMergeRefs,
} from "@chakra-ui/react";
import React, { ChangeEventHandler } from "react";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { G1Icon } from "../../utils/icons";
import { Component } from "../../utils/TypesAndConstants";
import { G1Input } from "../G1Input";

export interface PasswordFieldProps extends StyleProps {
    component: Component ;
    id?: string ;
    inputName?: string ;
    label?: string ;
    required?: boolean ;
    onChange?: ChangeEventHandler<HTMLInputElement> ;
    defaultValue?: any ;
    isValid?: boolean ;
    registerInput?: any ;
}

export const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>((props, ref) => {
    const { component, defaultValue, onChange, inputName, label, id, required, registerInput } = props ;
    const { isOpen, onToggle } = useDisclosure() ;
    const inputRef = React.useRef<HTMLInputElement>(null) ;
    const mergeRef = useMergeRefs(inputRef, ref) ;

    const onClickReveal = () => {
        onToggle() ;
        const input = inputRef.current ;
        if (input) {
            input.focus({ preventScroll: true }) ;
            const length = input.value.length * 2 ;
            requestAnimationFrame(() => {
                input.setSelectionRange(length, length) ;
            }) ;
        }
    }

    return (
        <FormControl isRequired={required} id={id} marginTop="5%">
            <Flex justify="space-between">
                <FormLabel>{label}</FormLabel>
            </Flex>
            <InputGroup
                bg={$g1Color(`${component}.field.bg`)}
                color={$g1Color(`${component}.field.write`)}
                borderColor={$g1Color(`${component}.field.border`)}
                _hover={{ borderColor: $g1Color(`${component}.field.hover.border`) }}
                _focus={{ borderColor: $g1Color(`${component}.field.select.border`) }}
            >
                <G1Input
                    component={component}
                    type={isOpen ? "text" : "password"}
                    defaultValue={defaultValue}
                    ref={mergeRef}
                    autoComplete="current-password"
                    formNoValidate
                    name={inputName}
                    isRequired={required}
                    placeholder="mot de passe"
                    {...registerInput}
                    onChange={onChange}
                    onBlur={onChange}
                    onInput={onChange}
                />
                <InputRightElement color={$g1Color(`${component}.field.icon`)}>
                    <IconButton
                        bg="transparent !important"
                        variant="ghost"
                        aria-label={isOpen ? "Masquer le mot de passe" : "Montrer le mot de passe"}
                        icon={isOpen ? <G1Icon.Hide /> : <G1Icon.View />}
                        onClick={onClickReveal}
                    />
                </InputRightElement>
            </InputGroup>
        </FormControl>
    ) ;
})

PasswordField.displayName = "PasswordField" ;
PasswordField.defaultProps = {
    required: false,
} ;